import React, { Fragment } from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { Link } from 'gatsby';
import { FaUserAlt } from '@react-icons/all-files/fa/FaUserAlt';
import { FaRegClock } from '@react-icons/all-files/fa/FaRegClock';
import { FaBookmark } from '@react-icons/all-files/fa/FaBookmark';

const propTypes = {
  author: shape({
    uri: string.isRequired,
    name: string.isRequired,
  }).isRequired,
  date: string.isRequired,
  categories: arrayOf(
    shape({
      uri: string.isRequired,
      name: string.isRequired,
    }),
  ).isRequired,
};

const PostInformation = ({ author, date, categories }) => {
  return (
    <ul className="h-clear-list mt-0 mb-2 c-blog-post-information">
      <li className="c-blog-post-information-item flex-shrink-0">
        <Link
          to={author.uri}
          className="text-secondary c-blog-post-information-item"
        >
          <FaUserAlt size={12} className="me-1" />
          {author.name}
        </Link>
      </li>
      <li className="c-blog-post-information-item flex-shrink-0">
        <FaRegClock size={12} className="me-1" />
        {date}
      </li>
      <li className="py-0">
        <ul className="h-clear-list m-0 c-blog-post-information flex-grow-1">
          <li className="py-0">
            <FaBookmark size={12} className="text-secondary me-1" />
            {categories.map(({ name, uri }, key) => {
              return (
                <Fragment key={uri}>
                  <Link
                    to={uri}
                    className="text-secondary c-blog-post-information-item"
                  >
                    {name}
                  </Link>
                  <span>{key < categories.length - 1 && ', '}</span>
                </Fragment>
              );
            })}
          </li>
        </ul>
      </li>
    </ul>
  );
};

PostInformation.propTypes = propTypes;

export default PostInformation;
